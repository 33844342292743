import { utils, read, writeFile } from "xlsx";
import { notification } from "ant-design-vue";
import { defineStore } from "pinia";
import { client } from "../config/dataService/dataService";
import Swal from "sweetalert2";

export const punchoutStore = defineStore("punchout", {
  state: () => ({
    _catalogue: [],
    _addresses: [],
    _globalCurrency:
      JSON.parse(localStorage.getItem("_globalCurrency")) || null,
    _totalSum: 0,
    _shippingAddress: null,
    _checkoutButtonSave: false,
    _billingAddress: null,
    _toggleBillingAddress: false,
    _paginationNumber: 1,
    _pageSize: 9,
    _itemSelected: JSON.parse(localStorage.getItem("_itemSelected")) || [],
    _unmatchedItems: JSON.parse(localStorage.getItem("_unmatchedItems")) || [],
    _isLoader: false,
    _item: {},
    _siteList: [],
    _quantity: 1,
    _cartSidebar: false,
    _searchTerm: "",
    _checkoutButton: false,
    _xmlItems: "",
    _xml: "",
    _formData: {},
    _site: "",
    _filters: [],
    _selectedFilterOption: "",
    _selectedFilterBoolean: false,
  }),
  getters: {
    catalogue() {
      const selectedFilter = this._selectedFilterOption;
      const searchTerm = this._searchTerm.toLowerCase();
      if (this._selectedFilterBoolean && searchTerm) {
        const searchTermsArray = searchTerm.split(" ");
        return this._catalogue.filter((item) => {
          return (
            searchTermsArray.every(
              (term) =>
                item.description.toLowerCase().includes(term) ||
                item.material.toString().toLowerCase().includes(term)
            ) && item.unspsc_text === selectedFilter
          );
        });
      }

      if (this._selectedFilterBoolean) {
        return this._catalogue.filter((item) => {
          if (item.unspsc_text === selectedFilter) {
            return item;
          }
        });
      }

      if (searchTerm) {
        const searchTermsArray = searchTerm.split(" ");
        return this._catalogue.filter((item) => {
          return searchTermsArray.every(
            (term) =>
              item.description.toLowerCase().includes(term) ||
              item.material.toString().toLowerCase().includes(term)
          );
        });
      }

      return this._catalogue;
    },

    quantity() {
      return this._quantity;
    },
    checkoutButton() {
      return this._checkoutButton;
    },
    searchTerm() {
      return this._searchTerm;
    },
    cartSidebar() {
      return this._cartSidebar;
    },
    siteList() {
      return this._siteList;
    },
    isLoader() {
      return this._isLoader;
    },
    itemXml() {
      return this._xmlItems;
    },
    xml() {
      return this._xml;
    },
    site() {
      return this._site;
    },
    siteModal() {
      if (!this._site.length) {
        return true;
      }
      else {
        return false;
      }
    },
    item() {
      return this._item;
    },
    getFilters() {
      return this._filters;
    },
    getBasketTotal() {
      return this._itemSelected.length + this._unmatchedItems.length;
    },
    nextButton() {
      if (this._shippingAddress == null) {
        return true;
      } else {
        return false;
      }
    },
    nextButtonBilling() {
      if (this._billingAddress == null) {
        return true;
      } else {
        return false;
      }
    },
    subtotal() {
      this._totalSum = 0;

      this._itemSelected.forEach((item) => {
        this._totalSum += item.subtotal;
      });
      return this._totalSum;
    },
  },
  actions: {
    getColumnHeaders(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            const data = new Uint8Array(e.target.result);
            const workbook = read(data, { type: "array" });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            let jsonData = utils.sheet_to_json(worksheet);


            jsonData = jsonData.map((item) => {
              return {
                material: item.Material || item.material,
                description: item.Description || item.description,
                quantity: item.Quantity || item.quantity,
                ...item
              };
            });

            resolve(jsonData);
          } catch (error) {
            reject(error);
          }
        };
        reader.readAsArrayBuffer(file);
      });
    },
    async quickFind(material, description, quantity) {
      let matchFound = false;

      this._catalogue.forEach((item) => {
        if (material == item.material) {
          matchFound = true;
          this._quantity = quantity;
          this.addItem(item);
          notification["success"]({
            message: "Item added to basket",
            description: `check basket`,
          });
        }
      });

      if (!matchFound) {
        const existingItem = this._unmatchedItems.find(
          (selectedItem) => selectedItem.material === material
        );

        if (existingItem) {
          existingItem.quantity += quantity;
        } else {
          this._unmatchedItems.push({
            material: material,
            description: description,
            quantity: quantity,
          });
        }

        notification["warning"]({
          message: "Unmatched Item added to basket",
          description: `check basket`,
        });
        localStorage.setItem(
          "_unmatchedItems",
          JSON.stringify(this._unmatchedItems)
        );
      }
    },
    async checkExcel(file) {
      if (file.size <= 5242880) {
        window.localStorage.setItem("reference", file.name);
        try {
          const jsonData = await this.getColumnHeaders(file);
          if (jsonData.length > 100) {
            new Swal({
              title: `100 item limit`,
              text: `There is a limit of 100 items or less, please update your excel file`,
              type: "warning",
              icon: "warning",
              confirmButtonColor: "#30afef",
              padding: "2em",
            });

            return;
          }
          let count = 0;
          const notFoundMaterials = [];
          this._catalogue.forEach((item) => {
            const matchingJsonItem = jsonData.find(
              (jsonItem) => jsonItem.material === item.material
            );
            if (matchingJsonItem) {
              count++;
              this._quantity = matchingJsonItem.quantity;
              this.addItem(item);
            }
          });
          jsonData.forEach((jsonItem) => {
            const found = this._catalogue.some(
              (item) => item.material === jsonItem.material
            );
            if (!found) {
              notFoundMaterials.push(jsonItem);
              this.quickFind(
                jsonItem.material,
                jsonItem.description,
                jsonItem.quantity
              );
            }
          });
          if (count > 0) {
            notification["success"]({
              message: "Items added to basket",
              description: `${count} items were added to the basket`,
            });
          } else {
            notification["info"]({
              message: "No items added",
              description:
                "No items from the catalogue were found in the Excel file.",
            });
          }
          if (notFoundMaterials.length > 0) {
            notFoundMaterials.map((item) => item.material).join(", ");
            new Swal({
              title: `Items not matched`,
              text: `Please download report to see which items are not matched. Unmatched items have been added to your basket.`,

              showDenyButton: true,
              type: "warning",
              icon: "warning",
              confirmButtonColor: "#30afef",
              denyButtonColor: "#1cfc03",
              denyButtonText: `Download Report`,
              padding: "2em",
            }).then((result) => {
              if (result.isDenied) {
                // Extract all unique keys from the objects in notFoundMaterials
                const allKeys = Array.from(
                  new Set(
                    notFoundMaterials.flatMap((item) => Object.keys(item))
                  )
                );

                // Create headers from all unique keys
                const headers = allKeys;

                // Create data rows by mapping each object to an array of values based on the headers
                const ws_data = notFoundMaterials.map((item) =>
                  headers.map((header) => item[header] || "")
                );

                // Add headers as the first row
                ws_data.unshift(headers);

                // Create a new workbook
                const wb = utils.book_new();

                // Create a worksheet
                const ws = utils.aoa_to_sheet(ws_data);

                // Add the worksheet to the workbook
                utils.book_append_sheet(wb, ws, "Sheet1");

                // Write the workbook to a file
                writeFile(wb, "items_not_matched.xlsx");

                console.log("Materials not found: ", notFoundMaterials);
              }
            });
          }
        } catch (error) {
          notification["error"]({
            message: "Error when using upload",
            description: `Error: ${error}`,
          });
          console.error("Error:", error);
        }
      } else {
        new Swal({
          title: `File error`,
          text: `File must be smaller than 5mb in size.`,
          type: "warning",
          icon: "warning",
          confirmButtonColor: "#30afef",
          padding: "2em",
        });
      }
    },

    toggleBillingAddress() {
      this._toggleBillingAddress = !this._toggleBillingAddress;
      if (this._toggleBillingAddress) {
        this._billingAddress = this._shippingAddress;
      } else {
        this._billingAddress = null;
      }
    },
    resetAddressess() {
      this._shippingAddress = null;
      this._billingAddress = null;
    },
    setShippingAddress(item) {
      this._shippingAddress = item;
    },
    setBillingAddress(item) {
      this._billingAddress = item;
    },
    resetQuantity() {
      this._quantity = 1;
    },
    addQuantityItem(index, type) {
      const data = type == 1 ? this._itemSelected : this._unmatchedItems;
      const text = type == 1 ? "_itemSelected" : "_unmatchedItems";

      data[index].quantity++;

      data[index].subtotal = parseFloat(
        (data[index].quantity * data[index].unit_price).toFixed(2)
      );
      localStorage.setItem(text, JSON.stringify(data));
    },
    customQuantityItem(index, quantity, type) {
      const data = type == 1 ? this._itemSelected : this._unmatchedItems;
      const text = type == 1 ? "_itemSelected" : "_unmatchedItems";

      data[index].quantity = Math.trunc(quantity);

      data[index].subtotal = parseFloat(
        (data[index].quantity * data[index].unit_price).toFixed(2)
      );
      localStorage.setItem(text, JSON.stringify(data));
    },
    removeQuantityItem(index, type) {
      const data = type == 1 ? this._itemSelected : this._unmatchedItems;
      const text = type == 1 ? "_itemSelected" : "_unmatchedItems";

      if (data[index].quantity > 1) {
        data[index].quantity--;
        data[index].subtotal = parseFloat(
          (data[index].subtotal - data[index].unit_price).toFixed(2)
        );
      }
      localStorage.setItem(text, JSON.stringify(data));
    },
    addQuantity() {
      this._quantity++;
    },
    removeQuantity() {
      if (this._quantity > 1) {
        this._quantity--;
      }
    },
    resetItemSelected(type) {
      if (type == 1) {
        this._itemSelected = [];
        localStorage.setItem(
          "_itemSelected",
          JSON.stringify(this._itemSelected)
        );
      } else if (type == 2) {
        this._unmatchedItems = [];
        localStorage.setItem(
          "_unmatchedItems",
          JSON.stringify(this._unmatchedItems)
        );
      }
    },
    addressess() {
      client
        .get(`catalogue/addresses`)
        .then((res) => {
          this._addresses = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submitData() {
      client
        .get(`catalogue/submitData`)
        .then((res) => {
          this._formData = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    singleItem(search) {
      this._searchTerm = search;
      client
        .get(`catalogue/elasticSearch?searchTerm=${encodeURIComponent(search)}`)
        .then((res) => {
          this._item = res.data.searchResults.hits.hits[0]._source;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    searchElastic() {
      client
        .get(`catalogue/elasticSearch?searchTerm=`)
        .then((res) => {
          localStorage.setItem(
            "_globalCurrency",
            JSON.stringify(res.data.searchResults.hits.hits[0]._source.currency)
          );
          const catalogue = res.data.searchResults.hits.hits.map(
            (hit) => hit._source
          );
          this._catalogue = catalogue;

          // Create a set to store unique site names
          const uniqueSites = new Set();

          // Iterate through _catalogue to add site names to the set
          this._catalogue.forEach(item => {
            if (item.site) {
              uniqueSites.add(item.site);
            }
          });

          // Convert the set to an array
          this._siteList = Array.from(uniqueSites);

          this.createFilterList();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    searchCatalogue() {
      let search = this._searchTerm;
      this._paginationNumber = 1;
      if (search.trim() === "") {
        this.searchElastic();
        this.filterCatalogue();
        return;
      }
      this.createFilterList();

    },

    updateFilter(option) {
      if (this._selectedFilterOption === option) {
        this._selectedFilterBoolean = false;
        this._selectedFilterOption = "";
      } else {
        this._selectedFilterOption = option;
        this._selectedFilterBoolean = true;
      }
      this.createFilterList();
    },
    resetFilter() {
      this._selectedFilterOption = "";
      this._selectedFilterBoolean = false;
      this._searchTerm = "";
      this.searchElastic();
    },
    createFilterList() {
      const items = this.catalogue;
      const unspscSet = new Set();
      items.forEach((item) => {
        const { unspsc_text } = item;
        unspscSet.add(unspsc_text);
      });
      this._filters = [...unspscSet].sort();
    },
    toggleCartSidebar() {
      this._cartSidebar = !this._cartSidebar;
    },
    toggleCheckoutButton() {
      this._checkoutButton = !this._checkoutButton;
    },
    toggleLoader() {
      this._isLoader = true;
      setTimeout(() => {
        this._isLoader = false;
      }, 500);
    },
    itemSelected(item) {
      this._item = item;
    },
    addItem(item) {
      const existingItem = this._itemSelected.find(
        (selectedItem) => selectedItem.item_code === item.item_code
      );

      if (existingItem) {
        existingItem.quantity += this._quantity;
        existingItem.subtotal = parseFloat(
          (existingItem.subtotal + this._quantity * item.unit_price).toFixed(2)
        );
      } else {
        item.quantity = this._quantity;
        item.subtotal = parseFloat(
          (this._quantity * item.unit_price).toFixed(2)
        );
        this._itemSelected.push(item);
      }
      localStorage.setItem("_itemSelected", JSON.stringify(this._itemSelected));
    },
    removeItem(index, type) {
      const data = type == 1 ? this._itemSelected : this._unmatchedItems;
      const text = type == 1 ? "_itemSelected" : "_unmatchedItems";

      if (index >= 0 && index < data.length) {
        data.splice(index, 1);
        localStorage.setItem(text, JSON.stringify(data));
      }
    },
    removeAllItem(type) {
      this.resetItemSelected(type);
    },
    clearSite() {
      this._site = "";
    },
    assignSite(site) {
      this._site = site;
      this.resetItemSelected(1);
      this.resetItemSelected(2);
      this.filterCatalogue();
    },
    async filterCatalogue() {
      let filteredCatalogue = [];
      if (this._site) {
        await this.searchElastic();
        setTimeout(() => {
          filteredCatalogue = this._catalogue.filter(item => item.site === this._site);
          this._catalogue = filteredCatalogue
        }, 1000);

      }

      console.log(this._site);
    }
  },
});
