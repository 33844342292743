<template>
  <div v-if="isLoading" class="spin">
    <a-spin />
  </div>
  <ThemeProvider
    v-else
    :theme="{
      mainContent,
      ...themeColor,
    }"
  >
    <Suspense>
      <template #default>
        <router-view></router-view>
      </template>
      <template #fallback>
        <div class="spin">
          <a-spin />
        </div>
      </template>
    </Suspense>
  </ThemeProvider>
</template>
<script>
import { ThemeProvider } from "vue3-styled-components";
import { themeColor } from "./config/theme/themeVariables";
import { computed, defineComponent, watch, onMounted } from "vue";
import { punchoutStore } from "./store/punchout.js";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import "v-calendar/dist/style.css";

export default defineComponent({
  name: "App",
  components: {
    ThemeProvider,
  },
  setup() {
    const { state } = useStore();
    const punchout = punchoutStore();
    const mainContent = computed(() => state.themeLayout.main);

    const route = useRoute(); // Get the current route

    const checkMainRoute = () => {
      if (route.path === "/") {
        punchout.filterCatalogue();
        // Place your custom logic here
      }
    };

    // Watch the route for changes
    watch(route, (newRoute) => {
      if (newRoute.path === "/") {
        checkMainRoute();
      }
    });

    // Check when the component is mounted
    onMounted(() => {
      checkMainRoute();
    });

    return {
      themeColor,
      mainContent,
    };
  },
});
</script>
